import { withYup } from "@remix-validated-form/with-yup";
import React, { useCallback, useRef } from "react";
import { useFormContext } from "remix-validated-form";
import { object, string } from "yup";
import { useValidatedForm } from "~/zd-ui/src/hooks/useValidatedForm";
import { LoginComponent } from "~common/components/Login/LoginComponent.tsx";
import { LoginMethodComponent } from "~common/components/Login/LoginMethodComponent.tsx";
import { useAuthenticateFetcher } from "~routes/api-/accounts-/authenticate/fetcher.ts";

export const loginSchema = object({
	login: string().required("Please enter the login"),
	password: string().required("Please enter the login"),
	method: string(),
});

export const loginValidator = withYup(loginSchema);

export const LoginForm = ({ title, children = null }) => {
	const fetcher = useAuthenticateFetcher();
	const { data, submit, state, typedData } = fetcher;

	const userId = typedData?.user?.id;
	const error = typedData?.error;

	const formId = "login-form";

	const submitRefData = useRef<{ login: string; password: string }>();

	const defaultValues = { login: "", password: "", method: "email" };
	const { validatedFormNode } = useValidatedForm(
		formId,
		loginSchema,
		defaultValues,
		false,
	);

	const { getValues } = useFormContext(formId);

	const authenticateUser = useCallback(() => {
		const login = getValues().get("login") as string;
		const password = getValues().get("password") as string;
		submitRefData.current = { login, password };
		if (login && password) {
			const formData = new FormData();
			formData.set("login", login);
			formData.set("password", password);
			submit(formData);
		}
	}, [getValues, submit]);

	return (
		<>
			{validatedFormNode}
			<div
				className={"h-dvh flex items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8"}
			>
				<div className={"w-full max-w-lg"}>
					<h2
						className={"mt-6 text-center text-3xl font-extrabold leading-9 text-gray-900"}
					>
						{title}
					</h2>
					<form
						id={formId}
						className="mt-8 pb-64"
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								console.log("enter");
								authenticateUser();
							}
						}}
					>
						{userId ? (
							<LoginMethodComponent
								formId={formId}
								submitData={submitRefData.current}
							/>
						) : (
							<LoginComponent
								formId={formId}
								authenticateUser={authenticateUser}
								error={error}
								isLoading={state !== "idle"}
							>
								{children}
							</LoginComponent>
						)}
					</form>
				</div>
			</div>
		</>
	);
};
