import { useNavigation } from "@remix-run/react";
import React, { useRef } from "react";
import { ZipButton, ZipRadioButtonsInput } from "~zd-ui";

export const LoginMethodComponent = ({
	formId,
	submitData,
}: {
	submitData: { login: string; password: string } | undefined;
	formId: string;
}) => {
	const submitRef = useRef<HTMLButtonElement>();

	const { state } = useNavigation();
	return (
		<>
			<input
				type="hidden"
				name="login"
				value={submitData?.login || ""}
				form={formId}
			/>
			<input
				className={"hidden"}
				type="password"
				form={formId}
				name="password"
				value={submitData?.password || ""}
			/>
			<span className={"text-center"}>
				Please select a confirmation method below, and we will send you a
				six-digit code.
			</span>
			<div className="rounded-md">
				<ZipRadioButtonsInput
					formId={formId}
					textField={"label"}
					valueField={"value"}
					className={"max-w-80 flex flex-col gap-y-4 text-center"}
					name={"method"}
					data={[
						{ label: "Email", value: "email" },
						{ label: "SMS", value: "sms" },
					]}
				/>
			</div>

			<div className="mt-6">
				<ZipButton
					formId={formId}
					type={"submit"}
					className={"group"}
					ref={submitRef}
					isLoading={state == "submitting"}
				>
					Next
				</ZipButton>
			</div>
		</>
	);
};
