import { Link, generatePath } from "@remix-run/react";
import React, { useRef } from "react";
import { $path } from "remix-routes";
import { useField } from "remix-validated-form";
import { ZipButton, useBrand } from "~zd-ui";

import { cn } from "@/lib/utils.ts";

export const LoginComponent = ({
	children,
	formId,
	authenticateUser,
	error,
	isLoading = false,
}: {
	children;
	formId: string;
	authenticateUser;
	error: string;
	isLoading?: boolean;
}) => {
	const submitRef = useRef<HTMLButtonElement>();

	const { brand } = useBrand();

	const { getInputProps: getInputPropsLogin } = useField("login", {
		formId: formId,
	});
	const { getInputProps: getInputPropsPassword } = useField("password", {
		formId: formId,
	});
	return (
		<>
			<input type="hidden" name="remember" value="true" />
			<div className="rounded-md shadow-sm">
				<div>
					<input
						aria-label="Email address"
						{...getInputPropsLogin()}
						type="email"
						form={formId}
						required
						className={
							"focus:shadow-outline-blue relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
						}
						placeholder="Email address"
					/>
				</div>
				<div className="-mt-px">
					<input
						aria-label="Password"
						{...getInputPropsPassword()}
						type="password"
						form={formId}
						required
						className={
							"focus:shadow-outline-blue relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
						}
						placeholder="Password"
					/>
				</div>
			</div>
			{error && <div className={"text-zd-red-500 mt-1 text-sm"}>{error}</div>}
			{children}
			<div className="mt-6 flex justify-between">
				<ZipButton
					formId={formId}
					type={"button"}
					handleClick={() => authenticateUser()}
					className={"group"}
					ref={submitRef}
				>
					<span className={"absolute inset-y-0 left-0 flex items-center pl-3"}>
						<svg
							className={
								"text-zd-blue-light h-5 w-5 transition duration-150 ease-in-out group-hover:text-white"
							}
							fill="currentColor"
							viewBox="0 0 20 20"
						>
							<path
								fillRule="evenodd"
								d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
								clipRule="evenodd"
							/>
						</svg>
					</span>
					{isLoading ? (
						<div
							className={cn({
								"border-5 h-6 w-6": true,
								"mx-auto my-0 box-border animate-spin rounded-full border-t-[#1992c5]": true,
							})}
						/>
					) : (
						"Sign in"
					)}
				</ZipButton>
				<Link
					to={generatePath("/accounts/password/reset")}
					className={cn({
						[`text-brand-${brand} hover:text-brandDark-${brand}`]: true,
						underline: true,
					})}
				>
					Forgot Password?
				</Link>
			</div>
		</>
	);
};
